import { FiShare2 } from "react-icons/fi";
import axios from "axios"

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const LinkSharing = props => {
    const [uniqueRoute, setUniqueRoute] = useState("")

    const getLink = () => {
        handleOpen()
        axios.post(`https://cfa-ide-backend.herokuapp.com/api/code`, {
            code: props.code
        })
            .then(res => {
                setUniqueRoute(res.data)
                console.log(res.data)
            })
            .catch(err => console.log(err));
        setUniqueRoute("")
    }
    // Modal Code
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className={props.className}>
            <button style={{
                backgroundColor: "transparent",
                cursor: "pointer",
                width: "auto",
                height: "auto"
            }}>
                <FiShare2 size="30" onClick={getLink} />
            </button>
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div className={classes.paper}>
                            <a id="uniqueLink" href={uniqueRoute ? `${window.location.origin}/share/${uniqueRoute}` : null}>{uniqueRoute ? `${window.location.origin}/share/${uniqueRoute}` : "Loading..."}</a>
                            <p id="transition-modal-title">Copy the following link</p>
                            <button onClick={handleClose}>Close</button>
                        </div>
                    </Fade>
                </Modal>
            </div>
        </div>
    )
}

export default LinkSharing