import React, { useState } from "react";
import CodeEditor from "./CodeEditor/CodeEditor";
import { FiPlay } from "react-icons/fi"
import { TiCancel } from "react-icons/ti"
import { MdContentCopy } from "react-icons/md"
import axios from "axios"

import "./IdeDashboard.css";
import codingForAll from "../Assets/codingforall.png"
import ErrorComponent from "./ErrorComponent";
import LinkSharing from "./LinkSharing";

function IDE(props) {
    const { codeText, setCodeText } = props;
    const [logs, setLogs] = useState([]);

    const runCode = () => {
        (function () {
            console.log = function (message) {
                if (typeof message == 'boolean') {
                    setLogs(logs => [...logs, [message.toString(), "boolean"]])
                }
                else if (typeof message == 'object') {
                    setLogs(logs => [...logs, [JSON.stringify(message), "object"]])
                }
                else if (typeof message == 'string') {
                    setLogs(logs => [...logs, ['"' + message + '"', "string"]])
                }
                if (typeof message == 'number') {
                    setLogs(logs => [...logs, [message, "number"]])
                }
            }
        })();

        try {
            const runFunc = new Function(codeText);
            const returnValue = runFunc();
        }
        catch (err) {
            const { message, stack } = err
            setLogs(logs => [...logs, [{ message, stack }, "error"]])
        }
    }


    return (
        <div className="IdeDashboard">
            <div className="IdeDashboard-main">

                <div className="IdeDashboard-header">
                    <a href="/"><img src={codingForAll} /></a>
                    <div className="header-content-wrapper">
                        <LinkSharing className="sharing-btn" code={codeText} />
                        <button className="run-code" onClick={() => {
                            runCode();
                            if (window.innerWidth < 550) {
                                document.location.href = "#end"
                            }
                        }}><FiPlay />Run</button>
                    </div>
                </div>
                <CodeEditor title="Test" value={codeText} onChange={setCodeText} />
            </div>
            <div className="IdeDashboard-console">
                <div className="IdeDashboard-console-container">
                    <p className="IdeDashboard-console-heading">Logs</p>
                    <button className="IdeDashboard-clear" onClick={() => setLogs([])}><TiCancel /></button>
                </div>
                {logs.length > 0 && logs.map((log, index) => {
                    return (
                        <p key={'l' + index} className={
                            log[1] == "boolean" ?
                                "IdeDashboard-console-output type-boolean" :
                                log[1] == "number" ?
                                    "IdeDashboard-console-output type-number" :
                                    log[1] == "object" ?
                                        "IdeDashboard-console-output type-object" :
                                        log[1] == "error" ?
                                            "IdeDashboard-console-output error" :
                                            "IdeDashboard-console-output"
                        }>
                            {!!log && !log[0].stack ?
                                log[0] :
                                (
                                    <ErrorComponent index={index} log={log} />
                                )
                            }
                        </p>
                    )
                }
                )}
                <div id="end"></div>
            </div>
        </div>
    )
}
export default IDE;