import React from 'react';
import { Route, Redirect } from "react-router-dom"

import Auth from "../utils/auth";

const PrivateRoute = ({Component, setCodeText}) => {
    return(
            Auth.getAuthStatus() ? 
                <Component setCodeText={setCodeText}/> :
                <Redirect to="/"/>
    );
}

export default PrivateRoute