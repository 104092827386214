import React, { useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import IDE from "./components/IdeDashboard";
import CameraInput from "./components/CameraInput";
import Homepage from "./components/Homepage/Homepage";
import PrivateRoute from "./components/PrivateRoute";
import Menu from './components/Menu';
import RenderUniqueLink from './components/RenderUniqueLink';

function App() {
  const [auth, setAuth] = useState(false)
  const [codeText, setCodeText] = useState("");
  return (
    <Router>
      <Switch>
        <Route path="/" exact component={Homepage} />
        <Route path="/camera" exact
          render={props => <PrivateRoute {...props} Component={CameraInput}
            setCodeText={setCodeText} />}
        />
        <Route path="/ide" exact
          render={props => <IDE {...props} codeText={codeText} setCodeText={setCodeText} />}
        />
        <Route path="/devtest" exact component={Menu} />
        <RenderUniqueLink />
      </Switch>
    </Router>
  );
}

export default App;