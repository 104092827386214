import axios from "axios";
import { useEffect, useState } from "react"
import { Route } from "react-router-dom"
import IDE from "./IdeDashboard"

const RenderUniqueLink = props => {
    const [data, setData] = useState({});
    const [codeText, setCodeText] = useState("");
    useEffect(() => {
        axios.post("https://cfa-ide-backend.herokuapp.com/api/link", {
            pathname: window.location.pathname
        }).then(({ data }) => {
            console.log(data)
            setCodeText(data.code)
            setData(data)
        }).catch(err => console.log(err))
    }, [])
    return (
        <Route path={`/share/${data.route}`} exact render={
            props => <IDE {...props} codeText={codeText} setCodeText={setCodeText} />
        } />
    )
}

export default RenderUniqueLink;