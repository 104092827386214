import React from 'react';
import axios from "axios";
import { withRouter } from "react-router-dom"
import Camera, { FACING_MODES  } from "react-html5-camera-photo";
import { TEXT_DETECTION_API_ENDPOINT, API_KEY, generateTextDetectionRequestBody } from '../utils/visionAPI';
import 'react-html5-camera-photo/build/css/index.css';

const CameraInput = (props) => {
    console.log(TEXT_DETECTION_API_ENDPOINT, API_KEY)
    const _getImageText = async base64Img => {
      const requestBody = generateTextDetectionRequestBody(base64Img);
      const { data } = await axios.post(
        TEXT_DETECTION_API_ENDPOINT, 
        requestBody,
        { params: { key: API_KEY } }
      );
      console.log(data)
      if(Object.keys(data.responses[0]).length===0) return;
      const result = data.responses[0].fullTextAnnotation.text;
      return result;
    }
    return(
        <Camera
        onTakePhoto={async (imageURI) => {
          const base64Img = imageURI.split(",").pop();
          const recogText = await _getImageText(base64Img);
          props.setCodeText(recogText);
          props.history.push("/ide");
        }} 
        idealFacingMode={FACING_MODES.ENVIRONMENT}
        isImageMirror={false}
        />
    );
}
export default withRouter(CameraInput);