import React, { useEffect, useState } from 'react';
import "./Homepage.css"
import logo from "../../Assets/codingforall.png"
import Auth from "../../utils/auth"

const Homepage = (props) => {
    let [authKey, setAuthkey] = useState(null)
    let [errorMsg, setErrorMsg] = useState(null)
    useEffect(() => {
        Auth.setAuthStatus(false)
    }, [])
    return (
        <div className="Homepage">
            <a href="/"><img src={logo} /></a>
            <div>
                <input type="password" placeholder="Enter the Key" onChange={(e) => {
                    setAuthkey(e.target.value)
                }}/>
                <p>{errorMsg}</p>
                <button onClick={() => {
                    Auth.handleAuth(authKey)
                    if(Auth.getAuthStatus()) {
                        props.history.push('/camera')
                    } else {
                        setErrorMsg("The key entered was invalid")
                    }
                }}>Go To Camera</button>
                <button onClick={() => {
                    props.history.push('/ide')
                }}>Go to IDE</button>
            </div>
        </div>
    );
}
export default Homepage