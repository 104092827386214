import * as dotenv from "dotenv";
import path from "path";
dotenv.config({ path: path.join(__dirname,'../src/.env') });
class Auth {
    constructor() {
        this.accessKey = process.env.REACT_APP_ACCESS_KEY
        this.authenticated = false
    }

    handleAuth(key) {
        if (this.accessKey === key) {
            this.setAuthStatus(true)
        }
    }
    getAuthStatus() {
        return this.authenticated
    }
    setAuthStatus(value) {
        this.authenticated = value
    }
}
export default new Auth()