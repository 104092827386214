import React, { useState } from 'react';
import { GoTriangleDown, GoTriangleRight } from "react-icons/go";

const ErrorComponent = (props) => {
    const [checkboxChecked, setCheckboxChecked] = useState(false)
    return (
        <div>
            <label for={"checkbox".concat(props.index)} onClick={() => {
                document.location.href = "#stack-end".concat(props.index)
            }}
            style={{
                display: "flex",
                alignItems: "center"
            }}
            >{
            checkboxChecked ? 
                <GoTriangleDown/> :
                <GoTriangleRight />} 
            {props.log[0].message}</label>
            <input id={"checkbox".concat(props.index)}
                onChange={() => {
                    setCheckboxChecked(state => !state)
                }}
                className="checkbox" 
                type="checkbox" />        
            <ul style={{
                margin: 0
            }}>
                <li>{props.log[0].stack}</li>
            </ul>
            <div id={"#stack-end".concat(props.index)}></div>
        </div>
    )
}

export default ErrorComponent