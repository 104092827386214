import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/javascript/javascript";
import { Controlled as ControlledEditor } from "react-codemirror2";
import "./CodeEditor.css";
const CodeEditor = (props) => {
    const handleChange = (editor, data, value) => props.onChange(value)
    return (
        <div className="CodeEditor">
            <ControlledEditor 
            className="CodeEditor-IDE"
            onBeforeChange={handleChange}
            value={props.value}
            options={{
                lineWrapping: true,
                lineNumbers: true,
                lint: true,
                theme: "material",
                mode: "javascript",
                smartIndent: true
            }}
            />
        </div>
)}
export default CodeEditor