import * as dotenv from "dotenv";
import path from "path";
dotenv.config({ path: path.join(__dirname,'../src/.env') });

export const TEXT_DETECTION_API_ENDPOINT = 'https://vision.googleapis.com/v1/images:annotate';

export const API_KEY = process.env.REACT_APP_VISION_API_KEY;

export const generateTextDetectionRequestBody = imgURI =>  ({
    "requests": [
      {
        "image": {
          "content": imgURI
        },
        "features": [
          {
            "type"  : "DOCUMENT_TEXT_DETECTION",
            "model" : "builtin/stable",
            "maxResults": 1
          }
        ]
      }
    ]
});


